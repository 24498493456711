import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";
import axios from "axios";

export const fetchAnalytics = createAsyncThunk(
  "fetchAnalytics34[",
  async (_, { getState }) => {
    try {
      const state = getState();
      const userId = state.auth.user.id;
      const response = await api.post(
        "/videotemplate/get-template-by-categoryID",
        { categoryID: userId }
      );
      return response.data.searchData;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("request was cancelled");
      } else {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    loading: false,
    error: "",
    data: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalytics.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchAnalytics.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
  },
});

export default analyticsSlice.reducer;
