import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchepisode } from "../redux/slice/Movie";
import Footer from "../components/Footer";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";
import Player from "../components/player/Player";
import Episodes from "../components/Episodes";
export default function Movie() {
  // get template id from url
  const { id } = useParams();
  const dispatch = useDispatch();

  const { movie, error, loading } = useSelector((state) => state.movie);

  useEffect(() => {
    dispatch(fetchepisode({ templateID: id }));
  }, []);
  // useEffect(() => {
  //   let timeOut = setTimeout()
  //   if (constrollsVisible === "active"){
  //   }

  // }, [constrollsVisible])

  if (loading) {
    return <Loader message="loading your episode" />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }
  return (
    <div>
      <Player movie={movie} />
      <Episodes />
      <Footer />
    </div>
  );
}
