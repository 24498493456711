import { Fragment, useEffect, useState } from "react";
import {
  googleLogin,
  updateUser,
  setMode,
  Applelogin,
} from "../redux/slice/Auth";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { upsertUser } from "../services/User";
import { fetchContinueWatch } from "../redux/slice/Movie";
import { setUser } from "../redux/slice/Auth";
import { closeModal } from "../redux/slice/LoginModal";
import mixpanel from "mixpanel-browser";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    mixpanel.track("Navigate to Login Page");
  }, []);
  function googlelogin() {
    dispatch(setMode("manuall"));
    dispatch(
      googleLogin(async function (payload) {
        setLoading(true);
        const userDetails = await upsertUser(payload);
        dispatch(
          setUser({
            user: {
              email: userDetails.emailID,
              imageUrl: userDetails.imageUrl,
              userName: userDetails.userName,
              id: userDetails.id,
              userChatUrl: null,
            },
          })
        );
        dispatch(closeModal());
        dispatch(fetchContinueWatch());
        navigate("/");
        setLoading(false);
        return userDetails;
      })
    );
  }

  function applelogin() {
    dispatch(setMode("manuall"));
    dispatch(
      Applelogin(async function (payload) {
        setLoading(true);
        dispatch(closeModal());
        const userDetails = await upsertUser(payload);
        navigate("/");
        setLoading(false);
        return userDetails;
      })
    );
  }

  return (
    <Fragment>
      <div className="mx-auto gap-4 text-white bg-[#1b1b1b] my-auto p-6 rounded-2xl flex flex-col shadow-lg">
        {loading ? (
          <div className="text-sm">Loading...</div>
        ) : (
          <Fragment>
            <img src="/logo2.png" className="h-[100px] mx-auto" />
            <span className="text-xl text-center mt-[-20px]">Zingroll</span>
            <section className="flex flex-col gap-4 my-4">
              <button
                disabled={loading}
                onClick={googlelogin}
                className="bg-gray-700 hover:bg-gray-600 outline-none cursor-pointer flex items-center gap-3 justify-center py-4 rounded-full px-6 transition duration-200"
              >
                <img src="/svg/google-icon.svg" className="size-4" />
                <span className="grow">Continue with Google</span>
              </button>
              <button
                disabled={loading}
                onClick={applelogin}
                className="bg-gray-700 hover:bg-gray-600 outline-none flex items-center gap-3 justify-center py-4 rounded-full px-6 transition duration-200"
              >
                <img className="size-4" src="/svg/apple-icon.svg" />
                <span className="grow">Continue with Apple</span>
              </button>
            </section>

            <span className="text-gray-400 font-light text-sm text-center">
              By continuing, you agree to our <br />
              <Link
                className="underline text-gray-300 hover:text-white"
                to="/terms"
              >
                Terms{" "}
              </Link>
              and acknowledge our{" "}
              <Link
                className="underline text-gray-300 hover:text-white"
                to="/privacy"
              >
                Privacy Policy
              </Link>
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Login;
