import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../redux/slice/LoginModal";
import { X } from "lucide-react";
import Login from "../../pages/Login";
const ReusableModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70"
      onClick={() => dispatch(closeModal())}
    >
      <div
        className="rounded-lg relative w-full max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        <X
          onClick={() => dispatch(closeModal())}
          size={25}
          className="cursor-pointer absolute top-4 right-4"
        />
        <Login />
      </div>
    </div>
  );
};

export default ReusableModal;
