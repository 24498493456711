import { useDispatch, useSelector } from "react-redux";
import { Fragment, useRef, useState } from "react";
import { uploadFileOnS3 } from "../../utils/aws";
import { ImagePlus, Loader, LoaderCircle, X } from "lucide-react";
import { api } from "../../utils/api";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { toast } from "react-toastify";
import { updateUser } from "../../redux/slice/Auth";
export default function EditGroupModal({ modalRef }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageInputRef = useRef();
  const imageFileRef = useRef();
  const [image, setImage] = useState(user?.imageUrl);
  const [name, setName] = useState(user?.userName);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          1, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const handleCrop = async () => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setUploading(true);
        try {
          const url = await uploadFileOnS3({ file, key: "image4upload" });
          console.log(url);
          setImage(url);
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setUploading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const resetFileInput = () => {
    setImage(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }
    if (imageFileRef.current) {
      imageFileRef.current.value = "";
      imageFileRef.current.files = null;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await api.post("/user/update-profile", {
        id: user.id,
        userData: {
          userName: name,
          imageUrl: image,
        },
      });
      dispatch(
        updateUser({
          data: {
            userName: name,
            imageUrl: image,
          },
        })
      );
      modalRef.current.close();
      toast.success("Profile updated successfully");
    } catch (error) {
      toast.error("error updating Profile");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-2xl w-full relative"
          onClick={(e) => e.stopPropagation()}
        >
          <X
            onClick={() => modalRef.current.close()}
            size={25}
            className="cursor-pointer absolute top-4 right-4"
          />
          <h2 className="text-xl font-semibold mb-6">Edit Profile</h2>
          <div className="gap-4 ">
            <div className="flex flex-col mb-2">
              {image ? (
                <div className="relative w-fit mx-auto mb-4">
                  <img
                    src={image}
                    alt="Category Preview"
                    className="w-40 h-40 object-cover rounded-full"
                  />
                  <button
                    onClick={resetFileInput}
                    className="absolute top-2 right-2 bg-zinc-700 rounded-full p-1 hover:bg-zinc-800"
                  >
                    <X />
                  </button>
                </div>
              ) : src ? (
                <div>
                  <div className="w-fit mx-auto ">
                    <ReactCrop
                      crop={crop}
                      onChange={(newCrop) => setCrop(newCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={16 / 9}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={src}
                        onLoad={onImageLoad}
                        className="w-52 h-52 "
                      />
                    </ReactCrop>
                  </div>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <div className="flex gap-2 max-w-sm mx-auto">
                    <button
                      onClick={() => setSrc(null)}
                      className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleCrop()}
                      className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Crop & Upload
                    </button>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <label
                    htmlFor="image-input"
                    className="flex mb-2 mx-auto cursor-pointer h-32 w-32 flex-col items-center justify-center border rounded-full border-dashed grow"
                  >
                    {uploading ? (
                      <Fragment>
                        <LoaderCircle className="animate-spin" size={32} />
                        {/* <span>uploading your image</span> */}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <ImagePlus size={32} />
                        {/* <span>select an image</span> */}
                      </Fragment>
                    )}
                  </label>
                  <input
                    disabled={uploading}
                    onChange={handleFileChange}
                    id="image-input"
                    ref={imageFileRef}
                    type="file"
                    accept="image/*"
                    className="absolute -top-[9999px]"
                  />
                </Fragment>
              )}
            </div>

            <div className="flex flex-col gap-4">
              {/* name and status */}
              <div className="flex gap-3">
                {/* name */}
                <div className="space-y-1 grow">
                  <label className="block  font-medium">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none"
                    placeholder="Enter category name"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 justify-end">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full mt-6"
            >
              {loading ? (
                <Loader size={25} className="animate-spin m-auto" />
              ) : (
                "Update Profile"
              )}
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
}
