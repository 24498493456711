import { combineReducers } from "redux";
import MovieSlice from "./slice/Movie";
import Profile from "./slice/Invite";
import AuthSlice from "./slice/Auth";
import Upload from "./slice/Upload";
import Modal from "./slice/LoginModal";

import GroupList from "./slice/templateGroups/groupList";
import GroupView from "./slice/templateGroups/groupView";
import GroupCreate from "./slice/templateGroups/groupCreate";
import GroupEdit from "./slice/templateGroups/groupEdit";
import EpisodeList from "./slice/templateGroups/episodeList";
import EpisodeEdit from "./slice/templateGroups/episodeEdit";
import Analytics from "./slice/Analytics";

const rootReducer = combineReducers({
  movie: MovieSlice,
  invite: Profile,
  auth: AuthSlice,
  upload: Upload,
  modal: Modal,
  analytics: Analytics,
  // group Functionality
  groupList: GroupList,
  groupView: GroupView,
  groupCreate: GroupCreate,
  groupEdit: GroupEdit,
  episodes: EpisodeList,
  episodeEdit: EpisodeEdit,
});

export default rootReducer;
