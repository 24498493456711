import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 1,
  prompt: "",
  description: "",
  name: "",
  templateGroupProfile: "",
  type: "",
  categoryID: "",
  categoryLabel: "",
};

const groupCreate = createSlice({
  name: "groupCreate",
  initialState,
  reducers: {
    resetTemplate(state, action) {
      state.name = "";
      state.description = "";
      state.templateGroupProfile = "";
      state.prompt = "";
      state.type = "";
      state.status = 1;
      state.categoryID = "";
      state.categoryLabel = "";
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setCategory(state, action) {
      state.categoryID = action.payload.id;
      state.categoryLabel = action.payload.name;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setDescription(state, action) {
      state.description = action.payload;
    },
    setProfile(state, action) {
      state.templateGroupProfile = action.payload;
    },
    setPrompt(state, action) {
      state.prompt = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
  },
});

export default groupCreate.reducer;
export const {
  setDescription,
  setName,
  setProfile,
  setPrompt,
  setStatus,
  setType,
  setCategory,
  resetTemplate,
} = groupCreate.actions;
