import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { clearAuth, logout } from "../redux/slice/Auth";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { openModal } from "../redux/slice/LoginModal";
import { ChartLine, LogOutIcon, Search, Tv2, User, Video } from "lucide-react";
export default function Dashboard() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearAuth());
    window.location.href = "/";
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".user-avatar")) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.target.search.value;
    navigate(`/search?q=${searchQuery}`);
  };

  return (
    <div className="flex justify-between items-center p-4 bg-[#1b1b1b] text-white">
      <div
        className="flex items-center hover:cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img src="/logo2.png" alt="Navbar Icon" className="w-12 h-12" />
      </div>
      <form
        onSubmit={handleSearch}
        className="flex items-center rounded-full bg-zinc-900 border border-zinc-700 focus-within:border-blue-500 w-52 md:w-96"
      >
        <Search size={20} className="ml-3" />
        <input
          type="text"
          autoComplete="off"
          placeholder="Search..."
          className="p-2 px-3 bg-zinc-900 focus:outline-none w-full rounded-full"
          name="search"
        />
      </form>
      <div className="relative">
        {user !== null ? (
          <>
            <div
              className="w-10 h-10 rounded-full mr-4 bg-[#ffffff1a] flex items-center justify-center user-avatar cursor-pointer"
              onClick={() => setIsPopupOpen(!isPopupOpen)}
            >
              <span className="text-white text-lg">
                {user?.email[0]?.toUpperCase() || "#"}
              </span>
            </div>
            {isPopupOpen && (
              <div className="absolute top-0 right-0 mt-12 bg-zinc-800 shadow-lg rounded-lg p-2 z-50 w-48">
                <Link
                  to="/my-videos"
                  className=" p-2 hover:bg-zinc-900 rounded-md flex gap-3 items-center"
                >
                  <Video size={20} />
                  My Videos
                </Link>
                <Link
                  to="/analytics"
                  className=" p-2 hover:bg-zinc-900 rounded-md flex gap-3 items-center"
                >
                  <ChartLine size={20} />
                  Analytics
                </Link>
                <Link
                  to="/profile"
                  className=" p-2 hover:bg-zinc-900 rounded-md flex gap-3 items-center"
                >
                  <User size={20} />
                  Profile
                </Link>
                <Link
                  to="/publish"
                  className=" p-2 hover:bg-zinc-900 rounded-md flex gap-3 items-center"
                >
                  <Tv2 size={20} />
                  Release Title
                </Link>
                <Link
                  className=" p-2 hover:bg-red-500 rounded-md flex gap-3 items-center"
                  onClick={handleLogout}
                >
                  <LogOutIcon size={20} />
                  Logout
                </Link>
              </div>
            )}
          </>
        ) : (
          <button
            // className="px-4 py-2rounded-md transition"
            className="rounded-full flex items-center mb-4 justify-between border-2 border-blue-500 px-4 py-2 text-white font-bold"
            onClick={() => dispatch(openModal())}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}
