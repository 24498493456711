import UploadVideos from "../components/Publish/uploadVideo";
import EnterDetails from "../components/Publish/Details";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import SelectVideo from "../components/Publish/selectVideo";
import { addUSerSeries, getUserVideos } from "../redux/slice/Upload";
import SelectSeries from "../components/Publish/SelectSeries";
import UploadTrailer from "../components/Publish/uploadTrailer";
import mixpanel from "mixpanel-browser";

function Publish() {
  useEffect(() => {
    mixpanel.track("Navigate to Publish Page");
  }, []);
  const dispatch = useDispatch();
  const { step, series } = useSelector((state) => state.upload);
  const { loadingGroups } = useSelector((state) => state.movie);
  useEffect(() => {
    if (series.length == 0) dispatch(addUSerSeries());
    dispatch(getUserVideos());
  }, [loadingGroups]);

  const renderSteps = () => {
    if (step == 1) return <SelectVideo />;
    else if (step == 2) return <UploadTrailer />;
    else if (step == 3) return <UploadVideos />;
    else if (step == 4) return <SelectSeries />;
    else if (step == 5) return <EnterDetails />;
    else return <UploadVideos />;
  };

  return (
    <div className="flex items-center justify-center h-screen sm:m-4 overflow-hidden">
      <div className="bg-zinc-800 p-4 sm:px-8 sm:py-4 rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll">
        {renderSteps()}
      </div>
    </div>
  );
}

export default Publish;
