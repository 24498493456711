import S3 from "aws-sdk/clients/s3";
export const s3 = new S3({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export async function uploadFileOnS3({
  file,
  bucket = "static-content-for-text",
  key = "web4image",
}) {
  const REGION = "us-east-1";
  const time = Date.now();
  const params = {
    Bucket: bucket,
    Key: `${key}/${time}-${file.name}`,
    Body: file,
  };
  try {
    const upload = await s3.putObject(params).promise();
    const encodedFileName = encodeURIComponent(`${time}-${file.name}`);
    const fileUrl = `https://${bucket}.s3.${REGION}.amazonaws.com/${key}/${encodedFileName}`;
    return fileUrl;
  } catch (error) {
    throw error;
  }
}

export async function clearBucket({ bucketName, prefix = "" }) {
  try {
    let isTruncated = true;
    let continuationToken;

    while (isTruncated) {
      // List objects in the bucket with the specified prefix
      const listParams = {
        Bucket: bucketName,
        Prefix: prefix, // Filter objects by prefix
        ContinuationToken: continuationToken, // For pagination
      };
      const listedObjects = await s3.listObjectsV2(listParams).promise();
      if (listedObjects.Contents.length === 0) break;

      // Prepare objects for deletion
      const deleteParams = {
        Bucket: bucketName,
        Delete: {
          Objects: listedObjects.Contents.map(({ Key }) => ({ Key })),
        },
      };

      console.log(deleteParams);

      // Delete objects in batches
      await s3.deleteObjects(deleteParams).promise();

      // Check if there are more objects to delete
      isTruncated = listedObjects.IsTruncated;
      continuationToken = listedObjects.NextContinuationToken;
    }

    // console.log(`All objects with prefix "${prefix}" deleted from bucket: ${bucketName}`);
  } catch (error) {
    console.error(
      `Failed to clear bucket ${bucketName} with prefix "${prefix}":`,
      error
    );
    throw error;
  }
}
