import React, { useState, useRef } from "react";
import { Pen, Eye, List, Image, Clapperboard, Video } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
export default function GroupListItem(props) {
  const {
    id,
    status,
    prompt,
    description,
    name,
    templateGroupProfile,
    type,
    categoryLabel,
    categoryID,
    viewGroup,
    editGroup,
    trailer,
    episodeList,
    trailerShown,
    trailerProgressBar,
    trailerProgressNumber,
  } = props;
  const imageRef = useRef();
  const [imageLoading, setImageLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className={`flex rounded-md bg-[#1d1d1e] p-4 hover:bg-zinc-800 transition-colors duration-200 flex-col relative hover:cursor-pointer`}
    >
      <div
        className="rounded-md relative overflow-hidden h-[250px]"
        onClick={() => {
          !trailerShown &&
            viewGroup({
              id,
              status,
              prompt,
              description,
              name,
              templateGroupProfile,
              type,
              categoryLabel,
              categoryID,
            });
        }}
      >
        {!trailerShown ? (
          templateGroupProfile ? (
            <img
              src={templateGroupProfile}
              ref={imageRef}
              onLoad={(e) => setImageLoading(false)}
              className={`object-cover h-full w-full ${
                imageLoading ? "animate-pulse" : ""
              } bg-gray-200`}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full w-full bg-zinc-800">
              <Image size={50} />
              <div className="mt-1">No Image Found</div>
            </div>
          )
        ) : (
          <video
            src={trailer.videoUrl}
            controls
            poster={trailer.imageUrl}
            className="size-full rounded-md object-cover"
          />
        )}
        {trailer && !trailerShown ? (
          <span className="absolute bg-white border rounded-full p-2 opacity-95 top-2 right-2">
            🎬
          </span>
        ) : null}
      </div>
      <div
        className={`flex items-center ${
          status ? "text-gray-600" : "text-gray-400"
        } mt-4`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col pr-4 grow">
          <span className="grow text-xl font-bold ">
            {!trailerShown ? name : trailer.name}
          </span>
          <span className="text-sm ">
            {!trailerShown ? description : trailer.description}
          </span>
        </div>
        <div className="flex">
          {/* edit button */}
          {!trailerShown && (
            <button
              className="flex items-center px-4 py-2 hover:bg-zinc-800 w-full"
              onClick={() => {
                // Handle edit action
                editGroup({
                  isTrailer: false,
                  id,
                  status,
                  prompt,
                  description,
                  name,
                  templateGroupProfile,
                  type,
                  categoryLabel,
                  categoryID,
                });
              }}
            >
              <Pen size={20} />
            </button>
          )}
          {trailer && trailerShown && (
            <button
              className="flex items-center px-4 py-2 hover:bg-zinc-800 w-full"
              onClick={() => {
                editGroup({
                  isTrailer: true,
                  id,
                  status,
                  prompt,
                  description,
                  name,
                  templateGroupProfile,
                  type,
                  trailer,
                  categoryLabel,
                  categoryID,
                  trailerProgressBar,
                  trailerProgressNumber,
                });
              }}
            >
              <Pen size={20} />
            </button>
          )}
          {/* view episodes button */}
          {!trailerShown && (
            <button
              className="flex items-center px-4 py-2 hover:bg-zinc-800 w-full"
              onClick={() => {
                episodeList(id, name, categoryID, templateGroupProfile);
              }}
            >
              <List size={25} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
