import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEpisodes } from "../redux/slice/Movie";
const ProductCard = ({ item, continueWatching = false, trailer = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    const id = item.templateGroupID || item.groupID || item.id;
    navigate(`/watch`, { state: { id, item } });
  };

  return (
    <div
      ref={cardRef}
      className={`rounded-lg mb-5 overflow-hidden flex flex-col transition-all duration-500 hover:cursor-pointer hover:scale-110 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
      onClick={handleClick}
    >
      <div className="relative">
        <img
          src={item.templateGroupProfile || item.imageUrl}
          alt={item.name}
          className="w-full h-64 md:h-72 object-cover"
        />
        {continueWatching && (
          <div style={Style.progress}>
            <div
              style={{
                ...Style.innerProgress,
                width: `${(item?.end_time / item?.total_duration) * 100}%`,
              }}
            />
          </div>
        )}
      </div>
      <div className="p-2 md:p-2  flex flex-col grow">
        <h4 className="text-md md:text-1xl font-semibold text-center">
          {item?.name?.replaceAll(
            "USERNAME",
            user?.userName?.split(" ")[0] || user?.firstName || "John"
          ) ||
            item?.title?.replaceAll(
              "USERNAME",
              user?.userName?.split(" ")[0] || user?.firstName || "John"
            )}
        </h4>
      </div>
    </div>
  );
};

const Style = {
  progress: {
    borderRadius: "50px",
    background: "#8b8b8b",
    height: "5px",
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    bottom: "0",
  },
  innerProgress: {
    background: "#009aff",
    height: "100%",
  },
};
export default ProductCard;
