// Loader.jsx
import { LoaderCircle as LucideLoader } from "lucide-react";

export default function Loader({ message = "Loading..." }) {
  return (
    <div className="flex flex-col items-center justify-center min-h-full text-white space-y-4">
      <LucideLoader className="animate-spin text-gray-400" size={35} />
      <span className="text-gray-300 text-sm">{message}</span>
    </div>
  );
}
