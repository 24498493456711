import React from "react";
import ReactDOM from "react-dom/client";
import "./stylesheet/index.css";
import App from "./App";
import mixpanel from "mixpanel-browser";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));

mixpanel.init(process.env.REACT_APP_MIXPANEL);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
        <ToastContainer autoClose={4000} hideProgressBar={false} theme="dark" />
      </Router>
    </PersistGate>
  </Provider>
);

/*
        // "components": [
        //   {
        //     "/": "/invite/*",
        //     "#": "/invite/*",
        //     "comment": "Matches URLs with /invite/ to redirect them as deep links to the app."
        //   },
        //   {
        //     "#": "/feed/*",
        //     "comment": "Matches URLs with a fragment starting with /feed/ and redirects them to the app."
        //   },
        //   {
        //     "#": "no_universal_links",
        //     "comment": "Matches any URL with a fragment that equals no_universal_links and instructs the system not to open it as a universal link.",
        //     "exclude": true
        //   },
        //   {
        //     "/": "/buy/*",
        //     "comment": "Matches any URL with a path that starts with /buy/."
        //   },
        //   {
        //     "/": "/help/website/*",
        //     "exclude": true,
        //     "comment": "Matches any URL with a path that starts with /help/website/ and instructs the system not to open it as a universal link."
        //   },
        //   {
        //     "/": "/help/*",
        //     "?": {
        //       "articleNumber": "????"
        //     },
        //     "comment": "Matches any URL with a path that starts with /help/ and that has a query item with name 'articleNumber' and a value of exactly four characters."
        //   }
        // ]

*/
