import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearVideo,
  setPoster,
  setSteps,
  addSeries,
  addSelectedSeries,
} from "../../redux/slice/Upload";
import { uploadFileOnS3 } from "../../utils/aws";
import { Copy, Plus, X, LoaderCircle, Trash } from "lucide-react";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
function SelectSeries() {
  const {
    videoUrl,
    title,
    description,
    poster,
    series,
    selectedSeries,
    noOfEpisodes,
  } = useSelector((state) => state.upload);
  const { user } = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [group, setGroup] = useState({
    name: "",
    description: "",
    category: "",
    categoryId: "",
    image: "",
  });
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCateId = (e) => {
    if (e.target.value == "Educational") {
      setGroup({
        ...group,
        categoryId: "641f1c52-b071-469c-b3e3-fdbf0766fff9",
        category: e.target.value,
      });
    } else if (e.target.value == "romance") {
      setGroup({
        ...group,
        categoryId: "d40c6afd-a392-4f5d-8f8a-18db20fbcbe6",
        category: e.target.value,
      });
    } else if (e.target.value == "Thriller") {
      setGroup({
        ...group,
        categoryId: "f8dba1f3-4286-4266-a04b-22f16f6027db",
        category: e.target.value,
      });
    } else if (e.target.value == "SciFi") {
      setGroup({
        ...group,
        categoryId: "38cc137a-b595-42d5-a22f-617c80f0937f",
        category: e.target.value,
      });
    } else if (e.target.value == "comedy") {
      setGroup({
        ...group,
        categoryId: "d3334e83-51a9-4a99-bd97-0affceb95965",
        category: e.target.value,
      });
    } else if (e.target.value == "horror") {
      setGroup({
        ...group,
        categoryId: "7b39a208-0add-49ce-a4ae-c30d45ee4a67",
        category: e.target.value,
      });
    } else if (e.target.value == "adventure") {
      setGroup({
        ...group,
        categoryId: "c03c2914-66c7-4486-8a23-7b0f68bb3853",
        category: e.target.value,
      });
    } else if (e.target.value == "drama") {
      setGroup({
        ...group,
        categoryId: "f7040bf9-a8a8-4244-aaef-6d58f28484d4",
        category: e.target.value,
      });
    } else if (e.target.value == "inspirational") {
      setGroup({
        ...group,
        categoryId: "d060505b-31de-44e6-a6c0-b472537e9cda",
        category: e.target.value,
      });
    } else {
      setGroup({
        ...group,
        categoryId: "7b2ad184-0cda-487e-af0a-55d9dd0b3720",
        category: e.target.value,
      });
    }
  };
  const handleSelectSeries = (e) => {
    setSelect(e.target.value);
    dispatch(addSelectedSeries(e.target.value));
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const handleCrop = async () => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setImageLoading(true);
        try {
          const url = await uploadFileOnS3({ file, key: "image4upload" });
          console.log(url);

          setGroup({ ...group, image: url });
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setImageLoading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (!group.image) {
      toast.error("Please select an image");
      return;
    }
    setLoading(true);
    const res = await api.post("/templategroup/store-template-group-data", {
      name: group.name,
      templateGroupProfile: group.image,
      description: group.description,
      prompt: "",
      type: "user-groups",
    });

    const update = await api.post("/templategroup/update-template-group-data", {
      id: res.data.categoryData.id,
      groupData: {
        categoryID: group.categoryId,
        userId: user.id,
        trailer: null,
        status: 0,
        categoryLabel: group.category,
      },
    });
    console.log(update.data);
    setLoading(false);
    setModal(false);
    setGroup({
      name: "",
      description: "",
      image: "",
    });
    toast.success("Series created successfully!");
    dispatch(addSeries(res.data.categoryData));
  };

  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">Select a series</h1>
        <X
          onClick={() => dispatch(setSteps(1))}
          size={25}
          className="cursor-pointer"
        />
      </div>
      <div className="flex flex-col justify-center h-[90%] max-w-xl m-auto">
        <label className="block text-md font-medium text-gray-300 ml-1 mb-1">
          Select a series
        </label>
        <div className="flex-col gap-5 h-20">
          <select
            className="block w-full border-2 border-zinc-600 rounded-md p-2 py-4 bg-zinc-800 focus:border-blue-500 outline-none"
            onChange={handleSelectSeries}
            required
          >
            <option value="">Select a series</option>
            {series.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <div className="text-gray-400 text-xl text-center my-3">OR</div>
          <div
            className="cursor-pointer bg-blue-500 rounded-lg justify-center items-center flex w-48 p-2 m-auto"
            onClick={() => setModal(true)}
          >
            <Plus size={25} /> Create a series
          </div>
          {select && (
            <div
              className="cursor-pointer bg-blue-500 rounded-lg justify-center items-center flex w-48 p-2 m-auto mt-20"
              onClick={() => dispatch(setSteps(5))}
            >
              Continue
            </div>
          )}
        </div>
      </div>
      {modal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60 overflow-auto"
          onClick={() => setModal(false)}
        >
          <div
            className="bg-zinc-800 p-4 rounded-lg relative w-full max-w-xl"
            onClick={(e) => e.stopPropagation()}
          >
            <X
              onClick={() => setModal(false)}
              size={25}
              className="cursor-pointer absolute top-4 right-4"
            />
            <h2 className="text-lg font-semibold mb-5">Create a series</h2>
            <form onSubmit={handleCreateGroup}>
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Name
              </label>
              <input
                type="text"
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                value={group.name}
                onChange={(e) => setGroup({ ...group, name: e.target.value })}
                required
              />
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Description
              </label>
              <textarea
                rows="5"
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                value={group.description.trim()}
                onChange={(e) =>
                  setGroup({ ...group, description: e.target.value })
                }
                required
              />
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Category
              </label>
              <select
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => getCateId(e)}
                required
              >
                <option value="">Select an category</option>
                <option value="Educational">Educational</option>
                <option value="romance">Romance</option>
                <option value="Thriller">Thriller</option>
                <option value="SciFi">SciFi</option>
                <option value="comedy">Comedy</option>
                <option value="horror">Horror</option>
                <option value="adventure">Adventure</option>
                <option value="drama">Drama</option>
                <option value="inspirational">Inspirational</option>
                <option value="other">Other</option>
              </select>
              <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                Cover Image
              </label>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => handleFileChange(e, 1)}
                id="file-image-2"
              />
              {imageLoading ? (
                <div className="m-auto mt-5">
                  <LoaderCircle className="animate-spin m-auto" size={22} />
                  <div className="text-sm text-gray-400 text-center">
                    Uplaod Image...
                  </div>
                </div>
              ) : group?.image ? (
                <div className="relative">
                  <img
                    className="w-[60%] aspect-video cursor-pointer m-auto"
                    src={group.image}
                    alt="Image"
                    onClick={() =>
                      document.getElementById("file-image-2").click()
                    }
                  />

                  <Trash
                    className="cursor-pointer absolute bottom-2 right-2"
                    size={20}
                    onClick={() => setGroup({ ...group, image: null })}
                  />
                </div>
              ) : (
                <>
                  {src ? (
                    <div>
                      <ReactCrop
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={16 / 9}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={src}
                          onLoad={onImageLoad}
                          className="max-w-full"
                        />
                      </ReactCrop>
                      <canvas ref={canvasRef} style={{ display: "none" }} />
                      <div className="flex gap-2">
                        <button
                          onClick={() => setSrc(null)}
                          className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleCrop()}
                          className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                        >
                          Crop & Upload
                        </button>
                      </div>
                    </div>
                  ) : (
                    <label htmlFor="file-image-2" className="cursor-pointer">
                      <div className="flex items-center text-center justify-center w-full h-32 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                        Upload Cover Image
                      </div>
                    </label>
                  )}
                </>
              )}
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full mt-4"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <LoaderCircle className="animate-spin mr-2" size={22} />
                  </div>
                ) : (
                  "Create Series"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectSeries;
