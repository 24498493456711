import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FAQAccordion from "../components/UI/FAQAccordian";
import mixpanel from "mixpanel-browser";

const reasons = [
  {
    title: "what is the  asd",
    description: "asd",
  },
  {
    title: "what is the  asd",
    description: "asd",
  },
  {
    title: "what is the  asd",
    description: "asd",
  },
];
export default function Faq() {
  useEffect(() => {
    mixpanel.track("Navigate to Faq Page");
  }, []);
  return (
    <div>
      <Header />
      <section className="mx-auto px-4 py-8 w-full max-w-7xl sm:px-12">
        <h2 className="text-[25px] md:text-[30px] lg:text-[30px] font-bold mb-8">
          FAQs
        </h2>
        <div className="space-y-4">
          {reasons.map((reason, index) => (
            <FAQAccordion
              key={index}
              question={reason.title}
              answer={reason.description}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}
