import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Hls from "hls.js";
import { FaPlay } from "react-icons/fa";
import PlayerControls from "./PlayerControls";
import PlayerOverlay from "./PlayerOverlay";
import { INITIAL_STATE, reducer } from "./Player.reducer";
import { LoaderCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { storeViews, storeWatchProgress } from "../../services/Videos";

const Player = (props) => {
  const { movie } = props;
  const navigation = useNavigate();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const playerRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hls, setHls] = useState(null);
  const [availableQualities, setAvailableQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(-1); // -1 for auto
  const stateRef = useRef(state);
  const movieRef = useRef(movie);

  useEffect(() => {
    const ext =
      movie?.videoUrl?.split(".")[movie?.videoUrl?.split(".")?.length - 1];
    if (Hls.isSupported() && movie?.videoUrl && ext === "m3u8") {
      const hlsInstance = new Hls({
        maxBufferLength: 30, // Set buffer length to 30 seconds
      });
      hlsInstance.loadSource(movie.videoUrl);
      hlsInstance.attachMedia(playerRef.current.getInternalPlayer());
      hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
        const levels = hlsInstance.levels.map((level, index) => ({
          index,
          height: level.height,
        }));
        setAvailableQualities(levels);
      });
      hlsInstance.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        setSelectedQuality(data.level);
      });
      setHls(hlsInstance);
    }
    return () => {
      if (hls && ext === "m3u8") {
        hls.destroy();
      }
    };
  }, [movie]);

  const handleQualityChange = (index) => {
    if (hls) {
      hls.currentLevel = index;
      setSelectedQuality(index);
    }
  };
  useEffect(() => {
    return () => {
      if (movie && stateRef.current.progress.playedSeconds != 0)
        storeProgress();
    };
  }, []);

  useEffect(() => {
    movieRef.current = movie;
    storeViews(movie);
  }, [movie]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const storeProgress = async () => {
    storeWatchProgress({
      videoId: movieRef?.current?.id,
      episode: movieRef?.current?.episodNumber,
      totalDuration: stateRef.current.duration,
      endTime: stateRef.current.progress.playedSeconds,
      url: movieRef?.current?.videoUrl,
      title: movieRef?.current?.name,
      desc: movieRef?.current?.description,
      imageUrl: movieRef?.current?.imageUrl,
      groupID: movieRef?.current?.templateGroupID,
      is_special: false,
    });
  };

  const handlePreview = () => {
    dispatch({ type: "PLAY" });
    dispatch({ type: "LIGHT", payload: false });
  };

  const handlePause = () => {
    dispatch({ type: "PAUSE" });
  };

  const handlePlay = () => {
    dispatch({ type: "PLAY" });
  };

  const handleEnded = () => {
    dispatch({ type: "LIGHT", payload: true });
    playerRef.current?.showPreview();
  };

  const handleProgress = (progress) => {
    dispatch({ type: "SEEK", payload: progress.playedSeconds });
  };

  const handleDuration = (duration) => {
    dispatch({ type: "DURATION", payload: duration });
  };

  const styles = {
    playerWrapper: {
      position: "relative",
      aspectRatio: "16 / 9",
      borderRadius: "8px",
      overflow: "hidden",
    },
    video: {
      borderRadius: "8px",
    },
    previewOverlay: {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent)",
    },
  };

  return (
    <div
      style={styles.playerWrapper}
      className={`player-wrapper ${state.light ? "light" : ""} ${
        state.playing ? "playing" : "paused"
      }`}
      ref={wrapperRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span
        onClick={() => dispatch({ type: state.playing ? "PAUSE" : "PLAY" })}
      >
        <ReactPlayer
          ref={playerRef}
          url={movie?.videoUrl}
          width="100%"
          height="100%"
          light={movie?.imageUrl}
          playIcon={<FaPlay />}
          controls={state.controls}
          loop={state.loop}
          muted={state.muted}
          playing={state.playing}
          playbackRate={state.playbackRate}
          volume={state.volume}
          onReady={() => setLoading(false)}
          onBuffer={() => setLoading(true)}
          onBufferEnd={() => setLoading(false)}
          onPlay={handlePlay}
          onEnded={handleEnded}
          onPause={handlePause}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onClickPreview={handlePreview}
        />
      </span>
      {state.playing && loading && (
        <div className="bg-[#00000082] absolute flex justify-center items-center top-0 right-0 left-0 bottom-0">
          <LoaderCircle className="animate-spin text-gray-400" size={50} />
        </div>
      )}
      <PlayerOverlay state={state} movie={movie} />
      {!state.controls && !state.light && (!state.playing || isHovered) && (
        <PlayerControls
          state={state}
          dispatch={dispatch}
          playerRef={playerRef}
          wrapperRef={wrapperRef}
          quality={
            <></>
            // availableQualities.length > 0 && (
            //   <div className=" bg-opacity-50 p-2 rounded">
            //     <select
            //       value={selectedQuality}
            //       onChange={(e) =>
            //         handleQualityChange(parseInt(e.target.value))
            //       }
            //       className="bg-zinc-900 text-white p-1 rounded"
            //     >
            //       <option value={-1}>Auto</option>
            //       {availableQualities.map((quality) => (
            //         <option key={quality.index} value={quality.index}>
            //           {quality.height}p
            //         </option>
            //       ))}
            //     </select>
            //   </div>
            // )
          }
        />
      )}
    </div>
  );
};

export default Player;
