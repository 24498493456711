import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    mixpanel.track("Navigate to Privacy Policy Page");
  }, []);
  return (
    <div className="text-white content overflow-y-auto flex flex-col p-4 gap-4 max-w-6xl m-auto">
      <div className="flex items-center gap-2">
        <img className="size-8" src="/logo2.png" />
        <h1 className="text-xl font-bold">Zingroll Privacy Policy</h1>
      </div>
      <p className="text-sm text-neutral-400">
        Last Updated: December 24, 2024
      </p>
      <p>
        At <b>Zingroll</b>, owned by <b>Harshit Foundation</b>, we value your
        privacy and are committed to protecting the personal information you
        provide. This Privacy Policy explains how we collect, use, store, and
        share your personal data when you use the Zingroll platform, including
        our website, application, and related services (collectively, the
        "Services").
      </p>
      <p>
        By accessing or using the Services, you agree to the collection and use
        of your information as described in this Privacy Policy. If you do not
        agree with our policies, please do not use the Services.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect different types of information when you use our Services:
      </p>
      <ul>
        <h3>a) Personal Information</h3>
        <p>
          Personal information is data that can be used to identify you or
          relate to you personally. This may include:
        </p>

        <li>
          <b>Account Information:</b> When you create an account on Zingroll, we
          collect personal details such as your name, email address, username,
          and password.
        </li>
        <li>
          <b>Payment Information:</b> If you make a purchase or subscribe to
          premium services, we may collect billing information, such as credit
          card details and billing address, though we use third-party payment
          processors to handle this data securely.
        </li>
        <li>
          <b>Profile Data:</b>You may provide additional information about
          yourself in your profile, such as preferences, settings, and usage
          patterns.
        </li>

        <h3>b) Usage Data</h3>
        <p>
          We automatically collect certain information when you use the
          Services, such as:
        </p>

        <li>
          <b>Account Information:</b> When you create an account on Zingroll, we
          collect personal details such as your name, email address, username,
          and password.
        </li>
        <li>
          <b>Device Information:</b> Information about the device you use to
          access our Services, including device type, operating system, browser
          type, IP address, and device identifiers.
        </li>
        <li>
          <b>Location Data:</b> If you enable location services on your device,
          we may collect your geographic location to provide location-based
          features (you can opt out of this at any time).
        </li>

        <h3>c) User Content</h3>
        <p>
          When you use the Services, you may upload, create, or share content,
          such as videos, images, audio files, and text ("User Content"). This
          content may include personal information depending on what you choose
          to upload. We store this content to allow you to access, edit, and
          share it across the platform.
        </p>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>
          <b>To Provide and Improve Our Services:</b> We use your information to
          deliver, maintain, and enhance the Services, including generating
          AI-powered content, personalizing your experience, and improving
          functionality.
        </li>
        <li>
          <b>To Communicate with You:</b> We may use your contact information to
          send you important updates, notifications, support messages, and
          promotional content (you can opt out of marketing communications at
          any time).
        </li>
        <li>
          <b>To Process Transactions:</b> If you purchase premium features or
          subscriptions, we use your payment information to process the
          transaction.
        </li>
        <li>
          <b>To Ensure Security:</b> We use your data to monitor for fraudulent
          activities and ensure the security and integrity of the platform.
        </li>
        <li>
          <b>To Comply with Legal Obligations:</b> We may use your information
          to comply with legal obligations, respond to subpoenas, or protect our
          legal rights.
        </li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information to third parties.
        However, we may share your information in the following cases:
      </p>
      <ul>
        <li>
          <b>Service Providers:</b> We may share your data with third-party
          vendors, contractors, and service providers who help us operate and
          maintain the platform, such as payment processors, hosting providers,
          analytics services, and customer support platforms. These providers
          are obligated to protect your data and use it solely for the purpose
          of providing services to Zingroll.
        </li>
        <li>
          <b>Business Transfers:</b> In the event of a merger, acquisition, or
          sale of assets, your personal information may be transferred as part
          of the transaction. We will notify you via email or notice on our
          website if your data is transferred to a third party.
        </li>
        <li>
          <b>Legal Requirements:</b> We may disclose your personal information
          if required to do so by law or in response to valid requests by public
          authorities (e.g., a court or government agency).
        </li>
        <li>
          <b>With Your Consent:</b> We may share your information with other
          parties if you have given explicit consent to do so.
        </li>
      </ul>

      <h2>4. Data Retention</h2>
      <p>
        We will retain your personal information for as long as necessary to
        fulfill the purposes outlined in this Privacy Policy, comply with legal
        obligations, resolve disputes, and enforce our agreements. When your
        data is no longer required, we will delete or anonymize it.
      </p>

      <h2>5. Your Rights and Choices</h2>
      <p>
        As a user, you have the following rights regarding your personal
        information:
      </p>
      <ul>
        <li>
          <b>Access and Correction:</b> You can request access to the personal
          information we hold about you and ask us to correct any inaccuracies
          or incomplete data.
        </li>
        <li>
          <b>Deletion:</b> You can request that we delete your personal
          information, subject to certain legal exceptions (e.g., if we are
          required to retain the data for legal reasons).
        </li>
        <li>
          <b>Opt-Out of Marketing:</b> You can opt out of receiving marketing
          emails or communications from us by following the unsubscribe
          instructions in the emails or adjusting your account settings.
        </li>
        <li>
          <b>Data Portability:</b> You may request a copy of your personal
          information in a structured, commonly used, and machine-readable
          format.
        </li>
        <li>
          <b>Withdrawal of Consent:</b>If we rely on your consent to process
          your data, you can withdraw that consent at any time by contacting us
          or adjusting your privacy settings. Note that withdrawal of consent
          may affect your ability to use certain features of the Services.
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at{" "}
        <b>
          <a href="mailto:support@zingroll.com">support@zingroll.com.</a>
        </b>
      </p>

      <h2>6. Data Security</h2>
      <p>
        We take reasonable measures to protect your data from unauthorized
        access, use, or disclosure, including encryption, secure storage, and
        access controls. However, no method of data transmission over the
        internet or electronic storage is 100% secure. While we strive to
        protect your personal information, we cannot guarantee its absolute
        security.
      </p>

      <h2>7. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies (e.g., web beacons,
        pixels) to collect information about your usage of the Services. Cookies
        help us provide a better experience, analyze trends, and customize
        content and advertisements.
      </p>
      <ul>
        <li>
          <b>Cookie:</b>A cookie is a small file stored on your device that
          allows us to remember your preferences and improve your experience.
        </li>
        <li>
          <b>Analytics:</b>We may use third-party analytics services (such as
          Google Analytics) to analyze user behavior on the platform. These
          services may collect information such as your IP address, browser
          type, and pages visited.
        </li>
      </ul>
      <p>
        You can manage your cookie preferences through your browser settings.
        Please note that disabling cookies may affect the functionality of some
        features of the Services.
      </p>

      <h2>8. International Data Transfers</h2>
      <p>
        As Zingroll operates globally, your personal information may be
        transferred and stored on servers located outside your country of
        residence, including in countries with different data protection laws.
        By using the Services, you consent to the transfer of your data to
        countries outside your jurisdiction.
      </p>

      <h2>9. Children’s Privacy</h2>
      <p>
        Zingroll does not knowingly collect or solicit personal information from
        children under the age of 13. If we become aware that a child under the
        age of 13 has provided us with personal information, we will take steps
        to delete such data. If you are a parent or guardian and believe that we
        may have collected personal information from a child, please contact us
        immediately.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will post any
        changes to this Privacy Policy on this page and update the "Last
        Updated" date at the top of the document. We encourage you to review
        this Privacy Policy periodically for any updates.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <ul>
        <li>
          <b>Email:</b>{" "}
          <a href="mailto:harshit@eggrobotics.com">harshit@eggrobotics.com</a>
        </li>
        <li>
          <b>Website:</b> <a href="http://www.zingroll.com">www.zingroll.com</a>
        </li>
      </ul>
      <p>
        By using Zingroll, you acknowledge that you have read, understood, and
        agree to the <a href="/#/terms">terms</a> of this Privacy Policy.
      </p>
    </div>
  );
}
