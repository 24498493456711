import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Loader from "../UI/Loader";
import { openModal } from "../../redux/slice/LoginModal";
import Header from "../Header";
function ProtectedRoute() {
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (loading) {
    return <Loader message="Loading..." />;
  }
  if (user) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  } else {
    dispatch(openModal());
    return <Navigate to="/" />;
  }

  return <Navigate to="/" />;
}

export default ProtectedRoute;
