import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 1,
  prompt: "",
  description: "",
  name: "",
  templateGroupProfile: "",
  type: "",
  categoryID: "",
  categoryLabel: "",
  isTrailer: false,
  trailer: null,
  videoUrl: null,
  trailerProgressNumber: 0,
  trailerProgressBar: false,
};

const groupEdit = createSlice({
  name: "groupEdit",
  initialState,
  reducers: {
    resetTemplate(state, action) {
      state.name = "";
      state.description = "";
      state.templateGroupProfile = "";
      state.prompt = "";
      state.type = "";
      state.status = 1;
      state.categoryID = "";
      state.categoryLabel = "";
      state.isTrailer = false;
      state.trailer = null;
      state.videoUrl = null;
    },
    setTemplate(state, action) {
      console.log(action.payload);
      if (action.payload.isTrailer) {
        state.name = action.payload.trailer.name || "";
        state.description = action.payload.trailer.description || "";
        state.templateGroupProfile = action.payload.trailer.imageUrl;
        state.videoUrl = action.payload.trailer.videoUrl;
        state.trailerProgressNumber = action.payload.trailerProgressNumber;
        state.trailerProgressBar = action.payload.trailerProgressBar;
      } else {
        state.name = action.payload.name;
        state.description = action.payload.description;
        state.templateGroupProfile = action.payload.templateGroupProfile;
      }
      state.prompt = action.payload.prompt;
      state.type = action.payload.type;
      state.status = action.payload.status;
      state.id = action.payload.id;
      state.categoryID = action.payload.categoryID;
      state.categoryLabel = action.payload.categoryLabel;
      state.isTrailer = action.payload.isTrailer;
      state.trailer = action.payload.trailer || null;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setCategory(state, action) {
      state.categoryID = action.payload.id;
      state.categoryLabel = action.payload.name;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setDescription(state, action) {
      state.description = action.payload;
    },
    setProfile(state, action) {
      state.templateGroupProfile = action.payload;
    },
    setPrompt(state, action) {
      state.prompt = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setTrailerProgressBar(state, action) {
      state.trailerProgressBar = action.payload;
    },
    setTrailerProgressNumber(state, action) {
      state.trailerProgressNumber = action.payload;
    },
  },
});

export default groupEdit.reducer;
export const {
  setDescription,
  setName,
  setProfile,
  setPrompt,
  setStatus,
  setType,
  setCategory,
  resetTemplate,
  setTemplate,
  setTrailerProgressBar,
  setTrailerProgressNumber,
} = groupEdit.actions;
