// TabNavBar.js
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const TabNavBar = ({ navs }) => {
  return (
    <div className="flex justify-center mt-5">
      <div className="flex gap-2 bg-zinc-800 rounded-full overflow-hidden px-4 pt-2">
        {navs.map((item, index) => (
          <NavLink
            key={index}
            to={item[0]}
            className={({ isActive }) =>
              isActive
                ? "text-blue-600 font-semibold border-b-2 border-blue-600 pb-2 px-4 py-2"
                : "text-gray-400 hover:text-blue-600 pb-2 px-4 py-2 "
            }
          >
            {item[1]}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default TabNavBar;
