import { useDispatch } from "react-redux";
import { setSteps, setVideoFile } from "../../redux/slice/Upload";
import { useState } from "react";
import { s3 } from "../../utils/aws";
import { Clapperboard, LoaderCircle, Video, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function UploadVideo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">Upload Video or Trailer</h1>
        <X onClick={() => navigate(-1)} size={25} className="cursor-pointer" />
      </div>
      <div className="flex flex-col items-center justify-evenly h-[90%] m-auto md:flex-row">
        <div className="flex flex-col items-center justify-center">
          <div
            className="border-2  border-gray-300 rounded-lg p-4 text-center cursor-pointer h-40 flex flex-col gap-4 items-center justify-center  w-full hover:cursor-pointer hover:border-blue-500"
            onClick={() => dispatch(setSteps(2))}
          >
            <Clapperboard size={40} />
            <div className="text-gray-100 w-[250px]">Upload Trailer</div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div
            className="border-2  border-gray-300 rounded-lg p-4 text-center cursor-pointer h-40 flex flex-col gap-3 items-center justify-center w-full hover:cursor-pointer hover:border-blue-500"
            onClick={() => dispatch(setSteps(3))}
          >
            <Video size={40} />
            <div className="text-gray-100  w-[250px]">Upload Video</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadVideo;
