import { useSelector } from "react-redux";
import { X, Image } from "lucide-react";
export default function ViewCategoryModal({ modalRef }) {
  // Fetch category details from the categoryView slice
  const {
    name,
    description,
    status,
    templateGroupProfile,
    categoryLabel,
    categoryID,
    id,
  } = useSelector((state) => state.groupView);

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-2xl w-full relative"
          onClick={(e) => e.stopPropagation()}
        >
          <X
            onClick={() => modalRef.current.close()}
            size={25}
            className="cursor-pointer absolute top-4 right-4"
          />
          <h2 className="text-2xl font-semibold mb-6">View Series</h2>
          <div className="gap-4">
            {/* Image with fallback */}
            <div className="mb-4">
              {templateGroupProfile ? (
                <img
                  src={templateGroupProfile}
                  alt="Category Profile"
                  className="w-40 h-40 object-cover rounded-md border border-gray-700"
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-40 w-40 bg-zinc-800">
                  <Image size={40} />
                  <div className="mt-1">No Image Found</div>
                </div>
              )}
            </div>

            <div className="space-y-1 mb-3">
              <label className="block font-medium">Category Label</label>
              <p className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none">
                {categoryLabel || "No category label  available"}
              </p>
            </div>

            {/* Category Name */}
            <div className="space-y-1 mb-3">
              <label className="block font-medium">Group Name</label>
              <p className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none">
                {name || "No name available"}
              </p>
            </div>

            {/* Description */}
            <div className="space-y-1 mb-3">
              <label className="block font-medium">Description</label>
              <p className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none">
                {description || "No description available"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
}
