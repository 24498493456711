import { useDispatch, useSelector } from "react-redux";
import Loader from "../UI/Loader";
import { Fragment, useState, useRef } from "react";
import { Edit, Pause, Pen, Play, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import generateUniqueId from "generate-unique-id";
import EditEpisode from "./editEpisode";
import { setEditEpisode } from "../../redux/slice/templateGroups/episodeEdit";
export default function EpisodeList({ modalRef }) {
  const { data, loading, error } = useSelector((state) => state.episodes);
  const editEpisode = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function Content() {
    if (loading) {
      return <Loader message="Fetching Episodes" />;
    }
    if (error) {
      return <div className="text-red-500">{error}</div>;
    }
    return (
      <Fragment>
        <X
          onClick={() => modalRef.current.close()}
          size={25}
          className="cursor-pointer absolute top-4 right-4"
        />
        <span className="flex items-center gap-2">
          <h1 className="text-xl font-bold">{data?.name}</h1>
          <span className="bg-blue-500 text-xs text-white p-1 px-3 rounded-full">
            {data?.episodes?.length} Episodes
          </span>
          <button
            onClick={(e) => {
              navigate("/publish");
            }}
            className="btn-primary ml-auto flex items-center gap-1 bg-blue-500 text-white px-2 py-1 rounded-md"
          >
            Add Episode
          </button>
        </span>
        <div className="overflow-y-auto flex-1">
          {data?.episodes?.length == 0 ? (
            <div className="flex items-center justify-center h-full">
              <span className="text-gray-400">No Episodes Found</span>
            </div>
          ) : (
            <div className="bg-zinc-900 flex flex-col gap-4 shadow-md justify-between rounded-lg md:items-center md:flex-row">
              <div className="overflow-x-auto w-full">
                <table className="min-w-full divide-y divide-zinc-600 w-full">
                  <thead className="bg-zinc-800 text-white">
                    <tr>
                      <th className="text-left py-2 px-4">Video</th>
                      <th className="text-left py-2 px-4">Views</th>
                      <th className="text-left py-2 px-4">Uploaded On</th>
                      <th className="text-left py-2 px-4">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-zinc-900 divide-y divide-zinc-600">
                    {data?.episodes?.map((item) => (
                      <EpisodeListItem key={item.id} {...item} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 h-auto min-h-[600px] p-8 px-3 md:px-6 rounded-lg shadow-lg justify-center max-w-5xl w-full space-y-6 flex-col flex gap-4 relative"
          onClick={(e) => e.stopPropagation()}
        >
          <Content />
        </div>
      </div>
      <EditEpisode modalRef={editEpisode} modalRef2={modalRef} />
    </dialog>
  );

  function EpisodeListItem(props) {
    const [isPlaying, setIsPlaying] = useState();
    const { description, name, id, status, videoUrl, episodNumber, imageUrl } =
      props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const togglePlayPause = () => {
      if (!videoRef.current) return;
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };
    const videoRef = useRef(null);
    return (
      <tr>
        <td>
          <div className="flex items-center gap-2">
            <div className="h-[150px] min-w-[250px] w-[250px] flex-shrink-0">
              <video
                ref={videoRef}
                src={videoUrl}
                controls
                poster={imageUrl || "/default.png"}
                className="size-full rounded-md object-cover"
              />
            </div>
            <div className="flex flex-col grow w-[200px]">
              <div className="grid  mb-auto">
                <span className="h-[26px]">
                  <b>Name: </b>
                  {name}
                </span>
                <span className="min-h-[26px]">
                  <b>Description: </b>
                  {description || <i>emtpy</i>}
                </span>
                <span className="h-[26px]">
                  <b>Episode Number: </b>
                  {episodNumber}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">{props.views || 0}</td>
        <td className="text-center">
          {new Date(props?.createdDate).toLocaleDateString()}
        </td>
        <td className="text-center">
          <button
            onClick={() => {
              dispatch(setEditEpisode(props));
              editEpisode.current.showModal();
            }}
            className="btn-primary text-white px-2 py-1 rounded-md hover:bg-blue-600"
          >
            <Pen size={20} />
          </button>
        </td>
      </tr>
    );
  }
}
