import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { api } from "../utils/api";
import Loader from "../components/UI/Loader";
import Series from "../components/Series";
import mixpanel from "mixpanel-browser";

function Search() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { search } = useLocation();
  const searchQuery = new URLSearchParams(search).get("q");

  useEffect(() => {
    if (searchQuery) {
      getData();
    }
    mixpanel.track("User search for", { search: searchQuery });
  }, [searchQuery]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.post("/templategroup/group-search", {
        text: searchQuery,
      });

      if (res.data.users.length == 0) {
        const res = await api.post("/templategroup/group-search", {
          text: "the",
        });
        setSearchResults(res.data.users);
        setLoading(false);
        return;
      }
      // console.log(res.data.users);
      setSearchResults(res.data.users);
    } catch (err) {
      console.log("error in search", err);
    }
    setLoading(false);
  };
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <section className="mx-auto px-4 py-8 w-full max-w-7xl sm:px-12">
          {searchResults.length > 0 ? (
            <div className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid grow mb-6 gap-4">
              {searchResults.map((result, index) => (
                <Series item={result} key={index} />
              ))}
            </div>
          ) : (
            <div className="mt-16">
              <h2 className="text-xl text-center">No results found</h2>
            </div>
          )}
        </section>
      )}
    </>
  );
}

export default Search;
