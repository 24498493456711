import { api } from "../utils/api";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMovie } from "../redux/slice/Movie";
import { openModal } from "../redux/slice/LoginModal";
export default function Episodes() {
  const dispatch = useDispatch();
  const { group, episodes, movie } = useSelector((state) => state.movie);
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  useEffect(() => {
    dispatch(setMovie(group.trailer));
    window.scrollTo({ top: 80, behavior: "smooth" });
  }, [movie]);

  useEffect(() => {
    if (user == null) {
      setShow(true);
    } else {
      getVote();
    }
  }, []);
  const getVote = async () => {
    try {
      const res = await api.get(
        `https://36iras96ql.execute-api.us-east-2.amazonaws.com/vote?userId=${user.id}&videoId=${group.groupID}`
      );
      if (res?.data?.data == null) {
        setShow(true);
      }
    } catch (err) {
      console.log("error in geting voting", err);
    }
  };
  const handleVote = async () => {
    if (user == null) {
      dispatch(openModal());
      return;
    }
    try {
      setShow(false);
      const res = await api.post(
        "https://36iras96ql.execute-api.us-east-2.amazonaws.com/vote",
        {
          userId: user.id,
          videoId: group.groupID,
          name: user.userName || user.emailID,
        }
      );
      if (res?.data?.status == 200) {
        toast.success("Vote successfully 🎉!");
        api.post("/templategroup/update-template-group-data", {
          id: group.groupID,
          groupData: {
            trailerProgressCount: group?.trailerProgressCount + 1 || 1,
          },
        });
        api.post("/userpodcast/update-podcast-data", {
          id: group.id,
          userPodcastsData: {
            trailerProgressCount: group?.trailerProgressCount + 1 || 1,
          },
        });
      } else {
        toast.error("something went wrong");
      }
    } catch (err) {
      console.log("error in voting", err);
    }
  };

  const Episode = ({ data }) => {
    // const duration =
    //   data.videoDuration < 60
    //     ? Math.floor(data.videoDuration) + "s"
    //     : Math.floor(data.videoDuration / 60) + "m";
    return (
      <div
        className="flex items-center max-w-full rounded-lg overflow-hidden p-2 hover:bg-[#ffffff1a] cursor-pointer flex-row sm:gap-4 gap-2 sm:m-4 mb-2 sm:mb-0"
        onClick={() => dispatch(setMovie(data))}
      >
        {/* <span className="text-white text-xl hidden sm:block">
          {data.episodNumber}
        </span> */}
        <div className="relative">
          <img
            src={data.imageUrl}
            alt={data.name}
            className={`w-40 h-20 object-cover rounded-xl sm:w-60 sm:h-28 `}
          />
          {/* {movie.id === data.id && (
            <PlayCircle
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              size={40}
            />
          )} */}
        </div>
        <div className="px-2 w-full sm:px-4">
          <div className="flex items-center justify-between">
            <h3 className="font-bold text-lg sm:text-xl text-white-800">
              {data.name}
            </h3>
            {/* <h3 className="font-bold text-xl text-white-800">
              {isNaN(duration) ? "" : duration}
            </h3> */}
          </div>
          <p className="text-gray-300 text-sm mt-1">{data.description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto px-4 py-8 w-full max-w-7xl md:px-12">
      {/* {group?.trailerProgressBar && (
        <div className="mb-10">
          <div className="flex items-center gap-2 justify-center">
            <div className="flex items-center h-2 bg-gray-300 w-full md:w-[80%] overflow-hidden rounded-full">
              <div
                className="h-full bg-gradient-to-r from-[#001aff] to-[#06b7f8] rounded-full animate-gradient"
                style={{
                  width: `${
                    (group.trailerProgressCount * group.trailerProgressNumber) /
                    100
                  }%`,
                }}
              ></div>
            </div>
            <span className="text-gray-400 text-sm">
              {(group.trailerProgressCount * group.trailerProgressNumber) / 100}
              %
            </span>
          </div>
          <button
            onClick={show ? handleVote : null}
            className="bg-gradient-to-r from-[#001affae] to-[#06b7f8] py-2 mt-5 rounded-lg w-40 block mx-auto animate-gradient"
          >
            <span className="">{show ? "Vote" : "Voted"}</span>
          </button>
        </div>
      )} */}
      <h2 className="text-2xl font-semibold text-white mb-4">Trailer</h2>
      <Episode key={1} data={group.trailer} />
    </div>
  );
}
