import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 1,
  prompt: "",
  description: "",
  name: "",
  templateGroupProfile: "",
  id: "",
  type: "",
  categoryID : "",
  categoryLabel : "",
};

const groupView = createSlice({
  name: "groupView",
  initialState,
  reducers: {
    setTemplate(state, action) {
      state.status = action.payload.status;
      state.templateGroupProfile = action.payload.templateGroupProfile;
      state.description = action.payload.description;
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.prompt = action.payload.prompt;
      state.categoryID = action.payload.categoryID;
      state.categoryLabel = action.payload.categoryLabel;
    },
    resetTemplate(state, action){
      state.name = "";
      state.description = "";
      state.templateGroupProfile = "";
      state.prompt = "";
      state.type = "";
      state.id = "";
      state.status = 1;
      state.categoryID = "";
      state.categoryLabel = "";
    }
  },
});

export default groupView.reducer;
export const {
  resetTemplate,
  setTemplate
} = groupView.actions;