import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/api";

export const fetchTemplateGroup = createAsyncThunk("fetchTemplateGroup", async () => {
  try {
    const res = await api.post("/templategroup/get-groupData-list", {
      limit: 1000,
      lastKey: null,
    });
    return res.data.searchData.sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate));
  } catch (error) {
    throw error;
  }
});

const initialState = {
  data: [],
  error: null,
  loading: false,
};


const GroupList = createSlice({
  name: "categoryList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateGroup.pending, (state, action) => {
      state.data = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTemplateGroup.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchTemplateGroup.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
  reducers: {
    addGroup(state, action) {
      state.data = [action.payload, ...state.data];
    },
    editGroup(state, action) {
      const { id, data } = action.payload;
      state.data = state.data.map((item) =>
        item.id === id ? { ...item, ...data } : { ...item }
      );
    },
  },
});

export default GroupList.reducer;
export const { addGroup, editGroup } = GroupList.actions;
