import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Loader from "../components/UI/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnalytics } from "../redux/slice/Analytics";
import mixpanel from "mixpanel-browser";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);
const Analytics = () => {
  const dispatch = useDispatch();
  const { loading, data: adata } = useSelector((state) => state.analytics);
  useEffect(() => {
    dispatch(fetchAnalytics());
    mixpanel.track("Navigate to Analytics Page");
  }, []);

  const viewsData = adata.map((video) => video.views || 0);
  const likesData = adata.map((video) => video.likes || 0);
  const commentsData = adata.map((video) => video.comments || 0);
  const data = {
    labels: adata.map((video) => video.name),
    datasets: [
      {
        label: "Views",
        data: viewsData,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 2,
      },
      {
        label: "Likes",
        data: likesData,
        fill: true,
        backgroundColor: "rgba(255,206,86,0.2)",
        borderColor: "rgba(255,206,86,1)",
        borderWidth: 2,
      },
      {
        label: "Comments",
        data: commentsData,
        fill: true,
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white",
        },
      },
      title: {
        display: true,
        text: "Video Analytics",
        color: "white",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
        },
      },
    },
  };

  const totalViews = viewsData.reduce((a, b) => a + b, 0);
  const totalLikes = likesData.reduce((a, b) => a + b, 0);
  const totalComments = commentsData.reduce((a, b) => a + b, 0);

  if (loading) return <Loader message="Loading..." />;
  return (
    <div className="bg-zinc-900 text-white p-4 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Analytics Dashboard
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="shadow-md rounded-lg p-4 bg-zinc-800">
          <h3 className="text-lg font-semibold">Total Views</h3>
          <p className="text-2xl">{totalViews}</p>
        </div>
        <div className="shadow-md rounded-lg p-4 bg-zinc-800">
          <h3 className="text-lg font-semibold">Total Likes</h3>
          <p className="text-2xl">{totalLikes}</p>
        </div>
        <div className="shadow-md rounded-lg p-4 bg-zinc-800">
          <h3 className="text-lg font-semibold">Total Comments</h3>
          <p className="text-2xl">{totalComments}</p>
        </div>
      </div>
      <div className="shadow-md rounded-lg p-4 bg-zinc-800">
        <h2 className="text-xl font-semibold mb-2">Engagement Over Time</h2>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default Analytics;
