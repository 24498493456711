import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  videoUrl: "",
  description: "",
  imageUrl: "",
};

const episodeEdit = createSlice({
  name: "episodeEdit",
  initialState,
  reducers: {
    setEditEpisode(state, action) {
      console.log(action.payload);
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.imageUrl = action.payload.imageUrl;
      state.videoUrl = action.payload.videoUrl;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setDescription(state, action) {
      state.description = action.payload;
    },
    setProfile(state, action) {
      state.imageUrl = action.payload;
    },
  },
});

export default episodeEdit.reducer;
export const { setEditEpisode, setName, setDescription, setProfile } =
  episodeEdit.actions;
