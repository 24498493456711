import React, { useEffect, useRef } from "react";
import SeriesCard from "../components/Myvideos/SeriesCard";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/UI/Loader";
import ViewGroupModal from "../components/Myvideos/groupView";
import EditCategoryModal from "../components/Myvideos/groupEdit";
import { setTemplate } from "../redux/slice/templateGroups/groupEdit";
import { setTemplate as setTemplateForView } from "../redux/slice/templateGroups/groupView";
import { fetchEpisodes } from "../redux/slice/templateGroups/episodeList";
import { addUSerSeries } from "../redux/slice/Upload";
export default function MyVideos() {
  const { series } = useSelector((state) => state.upload);
  const { loadingGroups } = useSelector((state) => state.movie);
  const isTraier = series.filter((e) => e.trailer);
  useEffect(() => {
    if (series.length == 0) {
      dispatch(addUSerSeries());
    }
  }, [loadingGroups]);
  const editModalRef = useRef();
  const viewModalRef = useRef();
  const dispatch = useDispatch();
  const listModalRef = useRef();
  const editGroup = (data) => {
    console.log(data);
    dispatch(setTemplate(data));
    editModalRef.current.showModal();
  };

  const viewGroup = (data) => {
    console.log(data);
    dispatch(setTemplateForView(data));
    viewModalRef.current.showModal();
  };

  const episodeList = (id, name, categoryID, imageUrl) => {
    dispatch(
      fetchEpisodes({ templateGroupID: id, name, categoryID, imageUrl })
    );
    listModalRef.current.showModal();
  };

  if (loadingGroups) {
    return <Loader message="loading..." />;
  }
  return (
    <section className="mx-auto px-4 py-8 w-full max-w-7xl sm:px-12">
      <div className="flex gap-5 items-center mb-8">
        <h2 className="text-[25px] md:text-[30px] lg:text-[30px] font-bold">
          Trailer
        </h2>
        <span className="bg-blue-500 text-sm text-white p-1 px-3 rounded-full">
          {isTraier?.length} Trailers
        </span>
      </div>
      {isTraier?.length != 0 ? (
        <div className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid grow min-h-0 overflow-y-auto mb-6 gap-4">
          {series?.map((item, index) => {
            if (item.trailer) {
              return (
                <SeriesCard
                  key={item.id}
                  trailerShown={true}
                  {...item}
                  editGroup={editGroup}
                  viewGroup={viewGroup}
                  episodeList={episodeList}
                />
              );
            }
          })}
        </div>
      ) : (
        <div className="flex items-center justify-center h-[50vh]">
          <h1 className="text-2xl font-bold text-gray-300">No Trailer Found</h1>
        </div>
      )}
      <EditCategoryModal modalRef={editModalRef} />
      <ViewGroupModal modalRef={viewModalRef} />
    </section>
  );
}
