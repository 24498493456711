import { api } from "../utils/api";
import { store } from "../redux/store";
import { addContinueWatch, updateContinueWatch } from "../redux/slice/Movie";
export const storeWatchProgress = async (e) => {
  try {
    const { user } = store.getState().auth;
    // return;
    const { continueWatch } = store.getState().movie;
    const isAvail = continueWatch.find((item) => item.podcastID == e.videoId);
    const isThere = isAvail == undefined;
    if (isThere) {
      const payload = {
        podcastID: e.videoId,
        userID: user.id,
        videoEpisodID: `${e.episode}`,
        total_duration: e.totalDuration,
        end_time: e.endTime,
        subscription: "0",
        coinsSpent: "0",
        url: e.url,
        title: e.title,
        description: e.desc,
        imageUrl: e.imageUrl,
        videoUrl: e.url,
        groupID: e.groupID || e.templateGroupID,
        is_special: e.is_special,
      };
      //   console.log(JSON.stringify(payload, null, 2));
      store.dispatch(addContinueWatch(payload));
      const response = await api.post(
        "/videosubscription/store-video-subscription-data",
        { ...payload }
      );
      //   console.log("watch progress store to server", response);
    } else {
      const payload = {
        id: isAvail.id,
        userVideoDurationsData: {
          videoEpisodID: `${e.episode}`,
          end_time: e.endTime,
          groupID: e.groupID || e.templateGroupID,
        },
      };
      store.dispatch(
        updateContinueWatch({
          id: e.videoId,
          endTime: e.endTime,
        })
      );

      //   console.log(JSON.stringify(payload, null, 2));
      const response = await api.post(
        "/videosubscription/update-video-subscription-data",
        { ...payload }
      );
      //   console.log("update watch progress store to server", response);
    }
    return false;
  } catch (error) {
    console.log("store watch progress service error", error);
    return false;
  }
};
export const storeViews = async (e) => {
  try {
    const response = await api.post(
      "/videotemplate/update-video-template-data",
      {
        id: e.id,
        videoTemplateData: {
          views: e?.views + 1 || 1,
        },
      }
    );
    // console.log("store views to server", response);
  } catch (error) {
    console.log("store views service error", error);
  }
};
