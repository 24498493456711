import React, { useEffect, useRef, useState } from "react";
import { Volume2, VolumeX, ChevronRight } from "lucide-react";
import Header from "../components/Header";
import Series from "../components/Series";
import { useSelector } from "react-redux";
import { LoaderCircle } from "lucide-react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import ReactPlayer from "react-player";
function Product() {
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const mainRef = useRef(null);
  const volumeButtonRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [muted, setMuted] = useState(true);
  const {
    loadingGroups,
    videoUrl,
    allGroups,
    for_you,
    trending,
    popular,
    continueWatch,
  } = useSelector((state) => state.movie);
  const { user } = useSelector((state) => state.auth);
  const handleScroll = () => {
    const scrollPosition = mainRef.current.scrollTop;
    const videoContainer = videoContainerRef.current;
    const video = videoRef.current;
    const volumeButton = volumeButtonRef.current;

    if (!videoContainer || !video) {
      return;
    }
    const maxScroll = 500;
    const scrollPercentage = Math.min(scrollPosition / maxScroll, 1);
    const maxMargin = 48;
    const margin = scrollPercentage * maxMargin || 0;

    videoContainer.style.paddingLeft = `${margin}px`;
    videoContainer.style.paddingRight = `${margin}px`;
    video.style.borderRadius = `${margin}px`;
    // volumeButton.style.marginRight = `${margin}px`;
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} slick-next absolute right-5 z-10 cursor-pointer`}
        style={{
          ...style,
          display: "block",
        }}
        onClick={onClick}
      >
        {/* <ChevronRight size={24} /> */}
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} absolute left-5 z-10 cursor-pointer`}
        style={{
          ...style,
          display: "block",
        }}
        onClick={onClick}
      >
        <span className="text-white">←</span>
      </div>
    );
  }

  return (
    <main
      ref={mainRef}
      onScroll={handleScroll}
      className="min-h-[100dvh] text-white flex flex-col overflow-auto scrollbar-sleek"
    >
      <Header />
      <div
        ref={videoContainerRef}
        className="box-border max-w-full mb-10 relative"
      >
        <div
          ref={videoRef}
          className="transition-all duration-300 ease-out w-full otline-none h-full object-cover overflow-hidden"
        >
          <ReactPlayer
            url={
              "https://static-content-for-text.s3.us-east-1.amazonaws.com/HLS-vidoes/mahahbaratf/master.m3u8"
            }
            width={"100%"}
            height={"100%"}
            playing={true}
            muted={muted}
            loop
            onReady={() => setVideoLoaded(true)}
            playsinline
            controls={false}
            className="transition-all duration-300 ease-out w-full otline-none h-full object-cover"
          />
        </div>
        {videoLoaded ? (
          <button
            ref={volumeButtonRef}
            onClick={() => {
              setMuted(!muted);
            }}
            className="bg-[#ffffff9d] rounded-full p-2 md:p-4 absolute top-10 right-10"
          >
            {muted ? <VolumeX size={15} /> : <Volume2 size={15} />}
          </button>
        ) : null}
      </div>
      {allGroups == null && loadingGroups ? (
        <div className="mt-4 self-center">
          <LoaderCircle size={35} className="animate-spin text-gray-400" />
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <Link
              to="/publish"
              className="rounded-full flex items-center mb-4 justify-between border-2 border-blue-500 px-4 py-2 text-white font-bold text-md w-[80%] md:w-[40%] lg:w-[30%] md:text-xl"
            >
              <span className="">Release your own title</span>
              <ChevronRight size={20} />
            </Link>
          </div>
          {/* {for_you?.length > 0 && (
            <section className="mx-auto px-4 sm:px-12 w-full max-w-7xl mb-4">
              <h2 className="text-[20px] md:text-[28px] lg:text-[30px] font-bold ml-2">
                For You
              </h2>
              <div className="relative">
                <Slider {...settings}>
                  {for_you?.map((item, index) => (
                    <div className="p-2" key={index}>
                      <Series item={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )} */}

          {user && continueWatch?.length > 0 && (
            <section className="mx-auto px-4 sm:px-12 w-full max-w-7xl mb-4">
              <h2 className="text-[20px] md:text-[28px] lg:text-[30px] font-bold ml-2">
                Continue Watching
              </h2>
              <div className="relative">
                <Slider {...settings}>
                  {continueWatch?.map((item, index) => (
                    <div className="p-2" key={index}>
                      <Series item={item} continueWatching={true} />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )}

          {trending?.length > 0 && (
            <section className="mx-auto px-4 sm:px-12 w-full max-w-7xl mb-4">
              <h2 className="text-[20px] md:text-[28px] lg:text-[30px] font-bold ml-2">
                Coming Soon
              </h2>
              <div className="relative">
                <Slider {...settings}>
                  {trending?.map((item, index) => (
                    <div className="p-2" key={index}>
                      <Series item={item} trailer={true} />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )}
          {popular?.length > 0 && (
            <section className="mx-auto px-4 sm:px-12 w-full max-w-7xl mb-4">
              <h2 className="text-[20px] md:text-[28px] lg:text-[30px] font-bold ml-2">
                Releasing this month
              </h2>
              <div className="relative">
                <Slider {...settings}>
                  {popular?.map((item, index) => (
                    <div className="p-2" key={index}>
                      <Series item={item} trailer={true} />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )}
          {/* {allGroups?.length > 0 && (
            <section className="mx-auto px-4 sm:px-12 w-full max-w-7xl mb-4">
              <h2 className="text-[20px] md:text-[28px] lg:text-[30px] font-bold ml-2">
                Extras
              </h2>
              <div className="relative">
                <Slider {...settings}>
                  {allGroups?.map((item, index) => (
                    <div className="p-2" key={index}>
                      <Series item={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )} */}
          <div className="flex justify-center items-center">
            <Link
              to="/publish"
              className="rounded-full flex items-center mb-4 justify-between border-2 border-blue-500 px-4 py-2 text-white font-bold text-md w-[80%] md:w-[40%] lg:w-[30%] md:text-xl"
            >
              <span className="">Release your own title</span>
              <ChevronRight size={20} />
            </Link>
          </div>
        </>
      )}
      <Footer />
    </main>
  );
}

const styles = {};
export default Product;
