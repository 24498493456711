import { PlayCircle } from "lucide-react";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMovie } from "../redux/slice/Movie";

export default function Episodes() {
  const dispatch = useDispatch();
  const { episodes, movie } = useSelector((state) => state.movie);

  useEffect(() => {
    window.scrollTo({ top: 80, behavior: "smooth" });
  }, [movie]);

  const Episode = ({ data }) => {
    const duration =
      data.videoDuration < 60
        ? Math.floor(data.videoDuration) + "s"
        : Math.floor(data.videoDuration / 60) + "m";
    return (
      <div
        className="flex items-center max-w-full rounded-lg overflow-hidden p-2 hover:bg-[#ffffff1a] cursor-pointer flex-row sm:gap-4 gap-2 sm:m-4 mb-2 sm:mb-0"
        onClick={() => dispatch(setMovie(data))}
      >
        <span className="text-white text-xl hidden sm:block">
          {data.episodNumber}
        </span>
        <div className="relative">
          <img
            src={data.imageUrl}
            alt={data.name}
            className={`w-40 h-20 object-cover rounded-xl sm:w-60 sm:h-28  ${
              movie.id === data.id && "opacity-60"
            }`}
          />
          {movie.id === data.id && (
            <PlayCircle
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              size={40}
            />
          )}
        </div>
        <div className="px-2 w-full sm:px-4">
          <div className="flex items-center justify-between">
            <h3 className="font-bold text-lg sm:text-xl text-white-800">
              {data.name}
            </h3>
            <h3 className="font-bold text-xl text-white-800">
              {isNaN(duration) ? "" : duration}
            </h3>
          </div>
          <p className="text-gray-300 text-sm mt-1">{data.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto px-4 py-8 w-full max-w-7xl md:px-12">
      <h2 className="text-2xl font-semibold text-white mb-4">Episodes</h2>
      {episodes?.map((item, index) => {
        return <Episode key={index} data={item} />;
      })}
    </div>
  );
}
