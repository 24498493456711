import { api } from "../utils/api";
import { setCookie } from "../utils/cookie";

export async function upsertUser({
  uid,
  email,
  displayName,
  photoURL,
  provider,
}) {
  // get user id from db
  let user;
  try {
    user = (await api.post("/auth/check-or-insert", { emailID: email })).data;
  } catch (err) {
    console.log(err);
    throw err;
  }

  // setCookie("access-token", result1.userToken);
  setCookie("userID", user.id);

  // sync user info if missing
  let missingInfo = null;

  if (!user?.emailID) {
    if (!missingInfo) missingInfo = {};
    missingInfo.emailId = email;
  }
  if (!user?.userName) {
    if (!missingInfo) missingInfo = {};
    missingInfo.userName = displayName;
  }
  if (!user?.imageUrl) {
    if (!missingInfo) missingInfo = {};
    missingInfo.imageUrl = photoURL || "/avatar.png";
  }

  user.imageUrl = user.imageUrl || "/avatar.png";

  if (!missingInfo) {
    return { id: user.id, ...user, email };
  }

  try {
    const updatedUSer = (
      await api.post("/user/update-profile", {
        id: user.id,
        userData: {
          emailID: email,
          userName: displayName,
          imageUrl: photoURL || "/avatar.png",
        },
      })
    ).data;

    return { id: user.id, ...updatedUSer.result.Attributes, email };
  } catch (err) {
    console.log(err);
    throw err;
  }
}
