import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TabNavBar from "../components/UI/TabNavBar";
import { List, CirclePlus, Pen, Video, Clapperboard } from "lucide-react";
import mixpanel from "mixpanel-browser";

export default function Templates() {
  useEffect(() => {
    mixpanel.track("Navigate to My Videos Page");
  }, []);
  const navs = [
    [
      "/my-videos/videos",
      <div className="flex items-center gap-2">
        <Video size={20} />
        Videos
      </div>,
    ],
    [
      "/my-videos/trailer",
      <div className="flex items-center gap-2">
        <Clapperboard size={20} />
        Trailer
      </div>,
    ],
  ];
  return (
    <Fragment>
      <TabNavBar navs={navs} />
      <Outlet />
    </Fragment>
  );
}
