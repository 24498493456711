import { Fragment, useEffect } from "react";
import Countdown from "../components/Counter";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

function Home() {
  const navigation = useNavigate();
  useEffect(() => {
    mixpanel.track("Navigate to Download app page");
  }, []);
  return (
    <div className="w-full h-full flex items-center flex-col justify-center">
      <Fragment>
        <img src="/logo2.png" className="h-[300px]" />
        {/* <Countdown /> */}
        {/* <Link
          to="/login"
          className="mt-8 self-center px-8 py-2 rounded-full flex items-center bg-[#272727]"
        >
          <span className="text-base">Login</span>
        </Link> */}
        <div className="p-4 flex flex-col items-center gap-3">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/zingroll-movies-about-you/id6466747851"
            className="mt-8 self-center px-8 py-2 rounded-full flex gap-3 items-center bg-[#272727]"
          >
            <img src={"/svg/apple.svg"} className="h-10" />
            <div className="flex flex-col">
              <span className="text-sm">Download on the</span>
              <span className="font-semibold text-2xl">App Store</span>
            </div>
          </a>

          <span className="text-[#777777] text-sm">
            Copyright © 2025, Harshit Foundation
          </span>
        </div>
      </Fragment>
      {/* {process.env.NODE_ENV === "development" ? (
        <div className="grid grid-cols-2 gap-4">
          <Link to="/invite/a7403d30-0e32-4ab4-82e2-3643d379c0fa" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"invite link"}
          </Link>
          <Link to="/share/38f94085-d480-492f-8364-6d02ca81d917" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"movie link"}
          </Link>
          <Link to="/terms"  className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"Terms"}
          </Link>
          <Link to="/privacy" className="bg-white text-center text-black px-4 py-2 rounded-md">
            {"Privacy"}
          </Link>
        </div>
      ) : (
        null
      )} */}
    </div>
  );
}

export default Home;
