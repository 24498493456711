import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { Pen } from "lucide-react";
import EditProfile from "../components/modal/EditProfile";
function Profile() {
  const user = useSelector((state) => state.auth.user);
  const editModal = useRef();
  useEffect(() => {
    mixpanel.track("Navigate to Profile Page");
  }, []);
  return (
    <div className="flex items-center justify-center h-screen sm:m-4 overflow-hidden">
      <div className="bg-zinc-800 p-4 sm:px-8 sm:py-4 rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll">
        <div className="flex justify-between items-center pb-4 border-b-2 border-zinc-700">
          <h1 className="text-xl font-bold">Profile</h1>
          <Pen
            onClick={() => editModal.current.showModal()}
            size={20}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-5 mt-5 m-auto max-w-xl">
          <div className="flex flex-col w-full">
            {user?.imageUrl ? (
              <img
                src={user?.imageUrl}
                alt="Category Preview"
                className="w-40 h-40 object-cover rounded-full mb-3 mx-auto"
              />
            ) : (
              <img
                src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                alt="Category Preview"
                className="w-40 h-40 object-cover rounded-full mb-3 mx-auto"
              />
            )}
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Name
            </label>
            <input
              id="title"
              value={user.userName}
              type="text"
              className="mb-3 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              disabled
            />
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-300 ml-1 mb-1"
            >
              Email
            </label>
            <input
              id="title"
              value={user.email}
              disabled
              type="text"
              className="mb-3 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
            />
          </div>
        </div>
      </div>
      <EditProfile modalRef={editModal} />
    </div>
  );
}

export default Profile;
